<template>
  <v-layout full-height>
    <v-main class="iec-page-gradient d-flex">
      <slot />
    </v-main>

    <GlobalAppFooter />
    <GlobalAppNotification />
  </v-layout>
</template>

<style scoped>
.iec-page-gradient {
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)), url('assets/images/login_bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  color: rgb(55, 67, 155);
}
</style>
